/* General Styles */
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  background-color: #f4f4f4;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
}
.title {
  font-family: Georgia, Helvetica, sans-serif; /* Choose an appropriate font stack */
  font-size: 84px; /* Adjust the font size as needed */
  font-weight: bold; /* Use 'bold' for emphasis */
  color: #e4eeec; /* Choose a text color that fits your design */
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  letter-spacing: 1px;
  line-height: 0.1;
  text-transform: uppercase;
}
.subtitle {
  font-family: Georgia, Helvetica, sans-serif; /* Choose an appropriate font stack */
  font-size: 24px; /* Adjust the font size as needed */
  font-weight: bold; /* Use 'bold' for emphasis */
  color: #e4eeec; /* Choose a text color that fits your design */
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  letter-spacing: 1px;
  line-height: 0.1;
  text-transform: uppercase;
}

/* Header Styles */
.header {
  background-color: #1f1f1f; /* Changed background color */
  color: #eef4f6; /* Changed text color */
  padding: 10px 0;
  align-items: center;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
  width: 100%;
}

/* App Styles */
.App {
  text-align: center;
  padding: 40px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
  color: white;
}

.App-link {
  color: #61dafb;
  font-size: 18px;
}

/* Table Styles */
table {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
  margin-top: 20px;
  margin: 0;
}

th,
td {
  padding: 12px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f4f4f4;
  min-width: 100px;
  max-width: 200px;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

td img {
  width: 25px;
  height: 25px;
}

/* Carousel Styles */
.carousel-container {
  perspective: 4000px;
  padding: 10px;
  width: 100%;
  max-width: 1200px;
  height: calc(81vh - 19px);
  position: relative;
  overflow: scroll;
  margin-top: 40px;
  margin: 0 auto;
}

.carousel {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  display: flex;
}

.carousel-item {
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  transform-origin: center center -300px;
  flex: 0 0 100%;
}

.carousel-item.active {
  opacity: 1;
}

.carousel-item-title {
  padding: 20px;
  font-size: 1.5em;
  text-align: center;
  background-color: #f4f4f4;
  border-bottom: 1px solid #ccc;
}

.carousel-item-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.carousel-item-table th,
.carousel-item-table td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ccc;
}

.carousel-item-table th {
  background-color: #f4f4f4;
}

.carousel-item-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.carousel-indicators {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.carousel-indicator {
  background-color: #ccc;
  border: none;
  margin: 0 5px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.carousel-indicator.active {
  background-color: #007bff;
  color: white;
}

.carousel-indicator:focus {
  outline: none;
}

/* Footer Styles */
.footer {
  background-color: #1f1f1f;
  color: rgb(135, 127, 127);
  padding: 20px;
  margin-top: 40px;
  text-align: center; /* Center-align footer content */
}

.footer-title {
  font-size: 24px;
  margin-bottom: 20px;
}

.footer-list {
  list-style: none;
  padding: 0;
}

.footer-list-item a {
  color: white;
  text-decoration: none;
  font-size: 18px;
}

.footer-list-item a:hover {
  color: #ffb612;
}

/* Footer Copy */
.copy {
  text-align: center;
  padding: 20px;
  font-size: 14px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.team-container {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 5px;
}

.stats {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}

.sign-negative {
  margin-right: 5px; /* Adjust as needed to align the negative sign */
  margin-left: 2px; /* Adjust as needed to align the negative sign */
}
.sign-positive {
  margin-right: 3px; /* Adjust as needed to align the negative sign */
}

/* Responsive Design */
@media (max-width: 820px) {
  /* Adjust the padding for header and footer */
  .header {
    padding: 10px 0;
  }

  .footer {
    padding: 10px 0;
  }

  /* Margin for the carousel container */
  .carousel-container {
    margin-top: 10px;
  }

  .title {
    font-size: 18px; /* Adjust the font size as needed */
  }

  .subtitle {
    font-size: 6px; /* Adjust the font size as needed */
  }

  /* Reduce the font size for body content */
  body {
    font-size: 14px;
  }

  /* Adjust the font size and padding for table cells */
  th,
  td {
    font-size: 12px;
    padding: 5px;
  }

  /* Center-align team container */
  .team-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }

  /* Reduce the spacing between the logo and team name */
  .team-name {
    margin-left: 5px;
  }
}

@media (max-width: 390px) {
  /* Adjust the padding for header and footer */
  .header {
    padding: 5px 0;
  }

  .footer {
    padding: 5px 0;
  }

  /* Margin for the carousel container */
  .carousel-container {
    margin-top: 5px;
  }

  .title {
    font-size: 14px; /* Adjust the font size as needed */
  }

  .subtitle {
    font-size: 6px; /* Adjust the font size as needed */
  }

  /* Reduce the font size for body content */
  body {
    font-size: 12px;
  }

  /* Adjust the font size and padding for table cells */
  th,
  td {
    font-size: 10px;
    padding: 3px;
  }

  /* Center-align team container */
  .team-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
  }

  /* Reduce the spacing between the logo and team name */
  .team-name {
    margin-left: 3px;
  }
}
@media (max-width: 414px) {
  /* Adjust the padding for header and footer */
  .header {
    padding: 3px 0;
  }

  .footer {
    padding: 3px 0;
  }

  /* Margin for the carousel container */
  .carousel-container {
    margin-top: 3px;
  }

  .title {
    font-size: 12px; /* Adjust the font size as needed */
  }

  .subtitle {
    font-size: 3px; /* Adjust the font size as needed */
  }

  /* Reduce the font size for body content */
  body {
    font-size: 10px;
  }

  /* Adjust the font size and padding for table cells */
  th,
  td {
    font-size: 8px;
    padding: 2px;
  }

  /* Center-align team container */
  .team-container {
    display: flex;
    align-items: center;
    flex-direction: column; /* Change to column layout */
    gap: 2px;
  }

  /* Reduce the spacing between the logo and team name */
  .team-name {
    margin-top: 2px; /* Adjust margin-top instead of margin-left */
  }

  /* Center-align team logo */
  .team-logo {
    text-align: center; /* Center-align the logo */
    margin-bottom: 2px; /* Add margin to separate logo and name */
  }
}

.iframe-carousel {
  overflow: visible;
  width: 600%;
}

.iframe-container {
  display: flex;
  gap: 0px; /* Adjust the gap between iframes as needed */
  overflow: show;
  white-space: nowrap; /* Prevent iframes from wrapping to the next line */
  animation: scroll 25s linear infinite; /* Adjust the animation duration as needed */
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
